import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

import img from '../../static/images/image0.jpeg'

function TemporaryName() {
    return (
        <Container component="section" sx={{ mt: 10, display: 'flex' }}>
            <Grid container>
                <Grid item xs={12} md={8} sx={{ zIndex: 1 }}>
                    <Box
                        sx={{
                            borderRadius: 10,
                            display: 'flex',
                            justifyContent: 'center',
                            bgcolor: '#CECECE',
                            py: 8,
                            px: 3,
                        }}
                    >
                        <Box textAlign="center" sx={{ maxWidth: 650 }}>
                            <Typography variant="h5">
                                Podjęta współpraca z Proton Instalacje Elektryczne to gwarancja rzetelności, solidności oraz nowoczesnych rozwiązań.
                            </Typography>
                            <br/>
                            <Typography variant="h5">
                                Zapewniamy opiekę instalatorską przez czas trwania całej budowy (rozdzielnie budowlane-tymczasowe, awarie).
                            </Typography>
                            <br/>
                            <Typography variant="h5">
                                Dbamy o jakość materiału oraz budżet inwestorów!
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{ display: { md: 'block', xs: 'none', mb: 5 }, position: 'relative' }}
                >
                    <Box
                        component="img"
                        src={img}
                        alt="call to action"
                        sx={{
                            borderRadius: 10,
                            position: 'absolute',
                            top: -28,
                            left: -18,
                            right: 0,
                            bottom: 0,
                            // width: '100%',
                            maxHeight: 440,
                            // height: '100%',
                            maxWidth: 600,
                        }}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export default TemporaryName;