import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

export default function AppFooter() {
    return (
        <Typography
            component="footer"
            sx={{ display: 'flex', bgcolor: '#039CEC', mt: 10}}
        >
            <Container sx={{ my: 8, display: 'flex', color: 'white' }}>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" marked='left' gutterBottom sx={{fontWeight: 600}}>
                            Proton Instalacje Elektryczne Krzysztof Boguniowski
                        </Typography>
                        <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
                            <Box component="li" sx={{ py: 0.5 }}>
                                <Typography>Bielsko-Biała, ul. Żywiecka 111</Typography>
                            </Box>
                            <Box component="li" sx={{ py: 0.5 }}>
                                <Typography>NIP: 9372755426</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" marked='left' gutterBottom sx={{fontWeight: 600}}>
                            Dane kontaktowe
                        </Typography>
                        <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
                            <Box component="li" sx={{ py: 0.5 }}>
                                <Typography>Telefon: 699-797-467</Typography>
                            </Box>
                            <Box component="li" sx={{ py: 0.5 }}>
                                <Typography>Email: protoninstalacjeelektryczne@gmail.com</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Typography>
    );
}