import React, {useEffect} from 'react';
import './App.css';
import AppAppBar from "./modules/views/AppBar";
import TemporaryName from "./modules/views/TemporaryName";
import AppFooter from "./modules/views/AppFooter";
import MainInfo from "./modules/views/MainInfo";
import PerformedServices from "./modules/views/PerformedServices";

const App = () => {
    useEffect(() => {
        document.title = "Proton";
    }, []);
    
  return (
      <React.Fragment>
          <AppAppBar />
          <MainInfo/>
          <PerformedServices/>
          <TemporaryName/>
          <AppFooter/>
      </React.Fragment>
  );
}

export default App;
