import Grid from "@mui/material/Grid";
import Typography from "../Typography";
import Box from "@mui/material/Box";
import * as React from "react";
import montaz1 from "../../../static/images/services/5_prace_montazowe/led_w_lazience.jpg";
import montaz4 from "../../../static/images/services/5_prace_montazowe/podswietlane_lustro.jpg";
import montaz3 from "../../../static/images/services/5_prace_montazowe/oswietlenie_schodowe.jpg";
import montaz2 from "../../../static/images/services/5_prace_montazowe/oswietlenie_zewnetrzne.jpg";
import PerformedService from "./service-template";

const imagesSources = {
    images: [
        {
            src: montaz1,
            alt: "proton"
        },
        {
            src: montaz2,
            alt: "proton"
        },
        {
            src: montaz3,
            alt: "proton"
        },
        {
            src: montaz4,
            alt: "proton"
        }
    ]
}

export default function InstallationWorks() {
    return (
        <PerformedService
            images={imagesSources.images}
            serviceHeader={
                <Grid item xs={12} sx={{mt: 4}}>
                    <Typography variant="h3" align="center" sx={{fontWeight: 600, fontSize: 32 }} >
                        5) Prace biało-montażowe:
                    </Typography>
                    <Box component="ul" alignContent="center" alignItems="center" sx={{ ml: 5, p: 0, alignItems: 'center', justifyContent: 'center'}}>
                        <Box component="li" sx={{ py: 0.5 }}>
                            <Typography variant="h4" sx={{ fontSize: 24}}>montaż gniazd elektrycznych</Typography>
                        </Box>
                        <Box component="li" sx={{ py: 0.5 }}>
                            <Typography variant="h4" sx={{ fontSize: 24}}>montaż włączników, czujników ruchu</Typography>
                        </Box>
                        <Box component="li" sx={{ py: 0.5 }}>
                            <Typography variant="h4" sx={{ fontSize: 24}}>montaż lamp, szynoprzewodów, ledów</Typography>
                        </Box>
                        <Box component="li" sx={{ py: 0.5 }}>
                            <Typography variant="h4" sx={{ fontSize: 24}}>instalacja przekaźników drobnej automatyki elektrycznej</Typography>
                        </Box>
                    </Box>
                </Grid>
            }
            scrollTriggerPoint={3200}/>
    )
}