import Grid from "@mui/material/Grid";
import * as React from "react";
import {useEffect, useState} from "react";
import PhotoCarousel, {Image} from "../photo-carousel";

export interface PerformedServiceProps {
    images: Image[],
    serviceHeader: any,
    scrollTriggerPoint: number
}

export default function PerformedService(props: PerformedServiceProps){
    const [isVisible, setIsVisible] = useState(false);
    
    useEffect(() => {
        const handleScroll = () => {
            const yOffset = window.scrollY;
            const triggerPoint = props.scrollTriggerPoint;

            if (yOffset > triggerPoint) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])
    
    return (
        <Grid container item className={`slide-in ${isVisible ? 'visible' : ''}`} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', mt: 5, backgroundColor: '#CECECE', borderRadius: 10, maxWidth: 1000}}>
            {props.serviceHeader}

            <PhotoCarousel images={props.images}/>
        </Grid>
    )
}