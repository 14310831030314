import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

import FuseBoxes from "../components/performed-services/fuse-boxes";
import Meters from "../components/performed-services/meters";
import ElectricalInstallation from "../components/performed-services/electrical-installation";
import Grounding from "../components/performed-services/grounding";
import InstallationWorks from "../components/performed-services/installation-works";
import AcceptanceProtocols from "../components/performed-services/acceptance-protocols";

function PerformedServices() {
    return (
        <Box
            component="section"
            sx={{ display: 'flex', bgcolor: '#039CEC' }}
        >
            <Container sx={{ mt: 10, mb: 30}}>
                <Grid container sx={{justifyContent: 'center'}}>
                    <Grid item xs={12}>
                        <Typography variant="h3" align="center" sx={{color:'white', fontWeight: 700}}>
                            {
                                'Realizowane usługi:'
                            }
                        </Typography>
                    </Grid>

                    <FuseBoxes/>
                    <Meters/>
                    <ElectricalInstallation/>
                    <Grounding/>
                    <InstallationWorks/>
                    <AcceptanceProtocols/>

                </Grid>
            </Container>
        </Box>
    );
}

export default PerformedServices;