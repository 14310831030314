import Grid from "@mui/material/Grid";
import Typography from "../Typography";
import protokol from "../../../static/images/services/6_protokoły/image1.jpeg";
import * as React from "react";
import PerformedService from "./service-template";

const imagesSources = {
    images: [
        {
            src: protokol,
            alt: "proton"
        }
    ]
}

export default function AcceptanceProtocols() {
    return (
        <PerformedService
            images={imagesSources.images}
            serviceHeader={
                <Grid item xs={12} sx={{mt:4}}>
                    <Typography variant="h3" align="center" sx={{fontWeight: 600, fontSize: 32 }}>
                        {
                            '6) Realizacja protokołów odbiorczych potwierdzających sprawność i niezawodność instalacji elektrycznych oraz badania okresowe.'
                        }
                    </Typography>
                </Grid>
            }
            scrollTriggerPoint={4300}/>
    )
}