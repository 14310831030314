import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Grid from "@mui/material/Grid";

export interface Image {
    src: string,
    alt: string
}

function PhotoCarousel(props: {images: Image[]})
{
    return (
        <Grid container className="service-grid-item" sx={{justifyContent: 'center', alignItems: 'center', width: '100%', mt:4, mb:4}}>
            <Grid item xs={10} sx={{backgroundColor: '#CECECE'}}>
                {props.images.length > 1 ? (
                    <Carousel navButtonsAlwaysVisible={true} sx={{width: '100%'}}>
                        {
                            props.images.map( (item, i) => <Item key={i} {...item}  /> )
                        }
                    </Carousel>                    
                ) : (
                    <Item {...props.images[0]}/>
                )}
            </Grid>
        </Grid>
    )
}

function Item(props: Image)
{
    return (
        <img src={props.src} alt={props.alt} className="carousel-item"/>
    )
}

export default PhotoCarousel;