import Grid from "@mui/material/Grid";
import Typography from "../Typography";
import instalacje1 from "../../../static/images/services/3_instalacje_elektryczne/IMG_0850.jpg";
import instalacje2 from "../../../static/images/services/3_instalacje_elektryczne/IMG_8367.jpg";
import instalacje3 from "../../../static/images/services/3_instalacje_elektryczne/IMG_8363.jpg";
import instalacje4 from "../../../static/images/services/3_instalacje_elektryczne/IMG_8375.jpg";
import * as React from "react";
import PerformedService from "./service-template";

const imagesSources = {
    images: [
        {
            src: instalacje1,
            alt: "proton"
        },
        {
            src: instalacje2,
            alt: "proton"
        },
        {
            src: instalacje3,
            alt: "proton"
        },
        {
            src: instalacje4,
            alt: "proton"
        }
    ]
}

export default function ElectricalInstallation(){
    return (
        <PerformedService
            images={imagesSources.images}
            serviceHeader={
                <Grid item xs={12} sx={{mt:4}}>
                    <Typography variant="h3" align="center" sx={{fontWeight: 600, fontSize: 32 }}>
                        {
                            '3) Układanie instalacji elektrycznej i teletechnicznej'
                        }
                    </Typography>
                </Grid>
            }
            scrollTriggerPoint={1700}/>
    )
}