import * as React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import proton_logo from '../../static/logo.jpg';

export default function MainInfo() {
    return (
            <Container sx={{ my: 8, display: 'flex' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sx={{alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                        <img src={proton_logo} alt="Proton" width='90%'/>
                    </Grid>
                    
                    <Grid item xs={12} md={6} sx={{alignItems: 'center', display: 'flex', justifyContent: 'center',}}>
                        <Typography variant="body1" gutterBottom sx={{fontSize: 24}}>
                            Firma <b>Proton Instalacje Elektryczne</b> zajmuje się doradztwem, serwisem i wykonawstwem <br/> instalacji elektrycznych oraz niskoprądowych. <br/><br/>
                            Działamy młodym wykwalifikowanym zespołem,<br/>który kompleksowo realizuje podjęte projekty domów, mieszkań oraz placówek publicznych.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
    );
}