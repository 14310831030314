import Grid from "@mui/material/Grid";
import Typography from "../Typography";
import * as React from "react";

import licznik from '../../../static/images/services/2_licznik/IMG_9510.jpg';
import PerformedService from "./service-template";

const imagesSources = {
    images: [
        {
            src: licznik,
            alt: "proton"
        }
    ]
}

export default function Meters() {
    return (
        <PerformedService
            images={imagesSources.images}
            serviceHeader={
                <Grid item xs={12} sx={{mt:4}}>
                    <Typography variant="h3" align="center" sx={{fontWeight: 600, fontSize: 32 }}>
                        {
                            '2) Zmiana przyłącza i lokalizacji licznika'
                        }
                    </Typography>
                </Grid>
            }
            scrollTriggerPoint={1000}/>
    )
}