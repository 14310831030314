import Grid from "@mui/material/Grid";
import Typography from "../Typography";
import uziemienie1 from "../../../static/images/services/4_uziemienie/IMG_2410.jpg";
import uziemienie2 from "../../../static/images/services/4_uziemienie/IMG_2409.jpg";
import * as React from "react";
import PerformedService from "./service-template";

const imagesSources = {
    images: [
        {
            src: uziemienie1,
            alt: "proton"
        },
        {
            src: uziemienie2,
            alt: "proton"
        }
    ]
}

export default function Grounding() {
    return (
        <PerformedService
            images={imagesSources.images}
            serviceHeader={
                <Grid item xs={12} sx={{mt:4}}>
                    <Typography variant="h3" align="center" sx={{fontWeight: 600, fontSize: 32 }}>
                        {
                            '4) Realizacja uziemień otokowych oraz szpilkowych'
                        }
                    </Typography>
                </Grid>
            }
            scrollTriggerPoint={2400}/>
    )
}