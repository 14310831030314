import Grid from "@mui/material/Grid";
import Typography from "../Typography";
import skrzynka1 from "../../../static/images/services/1_skrzynki/IMG_2358.jpg";
import skrzynka2 from "../../../static/images/services/1_skrzynki/IMG_2820.jpg";
import skrzynka3 from "../../../static/images/services/1_skrzynki/IMG_2361.jpg";
import skrzynka4 from "../../../static/images/services/1_skrzynki/part0000011.jpg";
import * as React from "react";
import PerformedService from "./service-template";

const imagesSources = {
    images: [
        {
            src: skrzynka1,
            alt: "proton"
        },
        {
            src: skrzynka2,
            alt: "proton"
        },
        {
            src: skrzynka3,
            alt: "proton"
        },
        {
            src: skrzynka4,
            alt: "proton"
        }
    ]
}

export default function FuseBoxes(){
    return (
        <PerformedService 
            images={imagesSources.images} 
            serviceHeader={
                <Grid item xs={12} sx={{mt:4}}>
                    <Typography variant="h3" align="center" sx={{fontWeight: 600, fontSize: 32 }}>
                        {
                            '1) Renowacja i wymiana skrzynek bezpiecznikowych'
                        }
                    </Typography>
                </Grid>
            } 
            scrollTriggerPoint={300}/>
    )
}